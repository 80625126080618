<template>
    <BlockContainer
        :background-color="settings.backgroundColor"
        :bottom-angle="settings.bottomAngle"
        :angle-background-color="settings.nextBlockBgColor"
    >
        <template #container>
            <ProductCarousel
                v-if="carouselData.slides.length"
                class="mt-16"
                :class="2 === carouselData.buttons.length ? 'mb-24' : 'mb-16'"
                :carousel-data="carouselData"
            />

            <div
                v-if="hasArrowCallout"
                class="container relative pt-14 lg:pt-0"
            >
                <BlockDividerArrow
                    v-if="hasArrowCallout"
                    :arrow-callout="arrowCallout"
                    class="absolute z-20"
                    :class="dividerArrowClasses"
                />
            </div>
        </template>
    </BlockContainer>
</template>

<script setup lang="ts">
import BlockContainer from '~/components/BlockContainer.vue';
import BlockDividerArrow from '~/components/BlockDividerArrow.vue';
import useStoryblokBlock from '~/composables/useStoryblokBlock';
import type { ProductCarouselData } from '~/types/ProductCarousel';
import ButtonType from '~/types/ButtonType';
import ProductCarousel from '~/components/page-building/ProductCarousel.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        default: null,
    },
});

const arrowCallout = computed(() => {
    return {
        type: props.blok.arrow_callout_type,
        text: props.blok.arrow_callout_text
            ? renderRichText(props.blok.arrow_callout_text)
            : '',
    };
});

const carouselData = computed(() => {
    const data: ProductCarouselData = {
        heading: props.blok.heading,
        subheading: props.blok.subheading,
        content: renderRichText(props.blok.content),
        slides: [],
        buttons: props.blok.buttons
            ? props.blok.buttons.map((button: any) => {
                  return {
                      label: button.label,
                      style: button.style,
                      url: button.link.cached_url,
                      vEditable: isLivePreview() ? button : null,
                  } as ButtonType;
              })
            : [],
        vEditable: isLivePreview() ? props.blok : null,
    };

    // When the carousel is set to loop, the total number of items must be at
    // least the number of items visible in the viewport * 2. If there are not
    // enough items, we'll need to duplicate the set as many times as necessary
    // to reach the required number of slides.
    do {
        props.blok.products.forEach((productCard: any) => {
            data.slides.push({
                image: {
                    src: productCard.image.filename,
                    alt: productCard.image.alt,
                },
                title: productCard.title,
                content: renderRichText(productCard.content),
                link: productCard.button?.length
                    ? linkFromStoryblokLinkBlock(productCard.button[0])
                    : undefined,
                vEditable: isLivePreview() ? productCard : null,
            });
        });
    } while (
        props.blok.products.length &&
        data.slides.length < 10 &&
        !isLivePreview()
    );

    return data;
});

const {
    settings,
    hasBottomAngle,
    hasBottomAngleDownLeft,
    hasBottomAngleDownRight,
    hasArrowCallout,
} = useStoryblokBlock(
    computed(() => props.blok),
    computed(() => props.nextBlock),
);

const dividerArrowClasses = computed(() => {
    const classes = ['left-[10%]'];

    if (!hasBottomAngle.value) {
        classes.push('bottom-10');
    } else if (hasBottomAngleDownLeft.value) {
        classes.push('bottom-6');
    } else if (hasBottomAngleDownRight.value) {
        classes.push('bottom-14');
    }

    return classes;
});
</script>
