<template>
    <div v-editable="carouselData.vEditable">
        <div class="container text-center">
            <h2>
                {{ carouselData.heading }}
            </h2>

            <p
                v-if="carouselData.subheading"
                class="mt-6 font-bold uppercase leading-normal text-drip md:text-xl"
            >
                {{ carouselData.subheading }}
            </p>

            <WysiwygContent
                v-if="carouselData.content"
                class="mx-auto mt-6 max-w-2xl"
                :content="carouselData.content"
            />
        </div>

        <div
            class="swiper relative mt-8 transition-opacity duration-1000"
            :class="{ 'opacity-0': !carouselLoaded }"
        >
            <Swiper
                slides-per-view="auto"
                :centered-slides="true"
                :lazy-preload-prev-next="3"
                :loop="!isLivePreview()"
                :modules="[Navigation]"
                :navigation="
                    {
                        disabledClass: 'text-grind/10 pointer-events-none',
                        hiddenClass: 'hidden',
                        lockClass: 'hidden',
                        navigationDisabledClass: 'hidden',
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    } as NavigationOptions
                "
                :watch-slides-progress="true"
                @set-transition="onSwiperTransition"
                @set-translate="onSwiperTranslate"
                @swiper="onSwiperInit"
            >
                <SwiperSlide
                    v-for="(slide, index) in carouselData.slides"
                    :key="`${index}-${kebabCase(slide.title)}`"
                    v-editable="slide.vEditable"
                    class="group pointer-events-none xs:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                >
                    <div class="slide-scale relative px-2 py-6">
                        <div class="pointer-events-auto aspect-square">
                            <StoryblokImage
                                :src="
                                    slide.image.src ||
                                    '/images/image-placeholder.svg'
                                "
                                :alt="slide.image.alt || undefined"
                                class="h-full w-full object-contain object-bottom"
                                width="360"
                                height="360"
                            />
                        </div>

                        <div
                            class="slide-additional-content mt-6 text-center transition-opacity"
                        >
                            <h3>{{ slide.title }}</h3>

                            <WysiwygContent
                                v-if="slide.content"
                                class="mt-3"
                                :content="slide.content"
                            />

                            <span
                                v-if="slide.link"
                                class="mt-2 inline-block font-semibold text-brew underline"
                            >
                                {{ slide.link.label }}
                            </span>
                        </div>

                        <a
                            v-if="slide.link"
                            class="slide-hover absolute -inset-x-2 inset-y-2 rounded-lg opacity-0 transition md:group-hover:shadow-card-lg"
                            :href="slide.link.url"
                        ></a>
                    </div>
                </SwiperSlide>
            </Swiper>

            <div
                class="pointer-events-none absolute left-2 right-2 top-6 z-10 select-none"
            >
                <div
                    class="absolute inset-0 pt-[50%] xs:pt-[24%] md:pt-[16%] lg:pt-[12%] xl:pt-[9.5%]"
                >
                    <div
                        class="pointer-events-auto absolute bottom-0 left-2 translate-y-[50%]"
                    >
                        <a
                            aria-label="Previous"
                            class="swiper-button-prev flex cursor-pointer rounded-md border border-grind bg-white p-2 text-grind transition hover:-translate-y-1 lg:hover:border-brew lg:hover:bg-brew lg:hover:text-white"
                        >
                            <Icon name="angle-left" class="text-[24px]" />
                        </a>
                    </div>

                    <div
                        class="pointer-events-auto absolute bottom-0 right-2 translate-y-[50%]"
                    >
                        <a
                            aria-label="Next"
                            class="swiper-button-next flex cursor-pointer rounded-md border border-grind bg-white p-2 text-grind transition hover:-translate-y-1 lg:hover:border-brew lg:hover:bg-brew lg:hover:text-white"
                        >
                            <Icon name="angle-right" class="text-[24px]" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="carouselData.buttons.length"
            class="container mt-4 flex flex-col-reverse justify-center gap-x-6 sm:flex-row"
        >
            <Button
                v-for="button in carouselData.buttons"
                :key="button.label"
                :button="button"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { isLivePreview } from '~/utils/storyblok';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import { NavigationOptions, Swiper as SwiperType } from 'swiper/types';
import type { ProductCarouselData } from '~/types/ProductCarousel';
import Button from '~/components/page-building/components/Button.vue';
import { kebabCase } from 'lodash-es';

const props = defineProps<{
    carouselData: ProductCarouselData;
}>();

const carouselLoaded = ref(false);
let swiper: SwiperType;

watch(
    () => props.carouselData,
    (newCarouselData, oldCarouselData) => {
        swiper.update();

        if (
            isLivePreview() &&
            newCarouselData.cards.length > oldCarouselData.cards.length
        ) {
            setTimeout(() => {
                swiper.slideTo(newCarouselData.cards.length - 1);
            }, 250);
        }
    },
    { deep: true },
);

const onSwiperInit = (swiperObj: SwiperType) => {
    carouselLoaded.value = true;
    swiper = swiperObj;
};

const onSwiperTransition = (swiperObj: SwiperType, duration: number) => {
    const { slides } = swiperObj;

    for (let i = 0; i < slides.length; i += 1) {
        slides[i].style.transitionDuration = `${duration}ms`;
    }
};

const onSwiperTranslate = (swiperObj: SwiperType, translate: number) => {
    const { width, slides, slidesSizesGrid } = swiperObj;
    const center = -translate + width / 2;

    const shouldScale = window.innerWidth >= 480;
    // The number of slides it takes to reach zero scaling.
    const scaleDecay = 3;

    for (let i = 0; i < slides.length; i += 1) {
        const slideEl: HTMLElement = slides[i];
        const slideScaleEl = slideEl.firstElementChild as HTMLElement;
        const slideAdditionalContentEl = slideEl.querySelector(
            '.slide-additional-content',
        ) as HTMLElement;

        const slideOffset = slideEl.swiperSlideOffset;
        const slideWidth = slidesSizesGrid[i];
        const centerOffset = Math.abs(
            (center - slideOffset - slideWidth / 2) / slideWidth,
        );

        const scale = shouldScale
            ? Math.max(0.7, 1 - centerOffset / scaleDecay)
            : 1;

        slideEl.style.opacity = scale.toString();
        slideEl.dataset.slideIsCentered = centerOffset < 0.1 ? 'true' : 'false';

        slideScaleEl.style.transform = `scale(${scale})`;
        slideScaleEl.style.transformOrigin = `center ${slideWidth / 2}px`;

        slideAdditionalContentEl.style.opacity = Math.max(
            0,
            1 - centerOffset,
        ).toString();
    }
};
</script>

<style scoped>
[data-slide-is-centered='true'] .slide-hover {
    opacity: 1;
    pointer-events: auto;
}
</style>
